<template>
    <div class="chart">
        <a-row>
          <a-col :span="9">
            <div class="item everday">
              <div class="tenu">
                <div cless="text">推荐每日摄入</div>
                <div class="num"><b>{{suggest_heatkc}}</b><span class="x">/</span>千卡</div>
              </div>
              <div class="progress">
                <a-progress
                  :percent="heatkcRate"
                  strokeColor="#f8ac30"
                />
              </div>
            </div>
          </a-col>
          <a-col :span="5">
            <div class="item">
              <div class="tenu">
                <div cless="text">碳水化合物</div>
                <div class="num"><b>{{carbohydr}}g</b></div>
              </div>
            </div>
          </a-col>
          <a-col :span="5">
            <div class="item">
              <div class="tenu">
                <div cless="text">蛋白质</div>
                <div class="num"><b>{{protein}}g</b></div>
              </div>
            </div>
          </a-col>
          <a-col :span="5">
            <div class="item">
              <div class="tenu">
                <div cless="text">脂肪</div>
                <div class="num"><b>{{fat}}g</b></div>
              </div>
            </div>
          </a-col>
        </a-row>
    </div>
</template>

<script>
export default {
  inject: ['eventBus'],
  data () {
    return {
      suggest_heatkc: 0,
      heatkc: 0,
      protein: 0,
      fat: 0,
      carbohydr: 0
    }
  },
  computed: {
    heatkcRate () {
      return this.suggest_heatkc > 0 ? Math.round(this.heatkc / this.suggest_heatkc * 100) : 0
    }
  },
  methods: {
    updateHandle (suggestHeatkc, heatkc, protein, fat, carbohydr) {
      this.suggest_heatkc = suggestHeatkc
      this.heatkc = heatkc
      this.protein = protein
      this.fat = fat
      this.carbohydr = carbohydr
    }
  },
  created () {
    this.eventBus.$on('nutrition-update-mealdata', this.updateHandle)
  },
  beforeDestroy () {
    this.eventBus.$off('nutrition-update-mealdata', this.updateHandle)
  }
}
</script>
