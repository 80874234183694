<template>
   <div class="correlation-canteen">
        <div class="title">相关食堂/供货商的数据</div>
        <div class="list">
          <a-row>
            <a-col :span="8">
              <div class="item">
                <div class="hd">食堂</div>
                <div class="bd">
                  <div class="cell" v-for="(item,index) in canteens" :key="index">{{item.name}}</div>
                </div>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="item">
                <div class="hd">厨师</div>
                <div class="bd">
                  <div class="cell" v-for="(item,index) in cooks" :key="index">{{item}}</div>
                </div>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="item">
                <div class="hd">供应商</div>
                <div class="bd">
                  <div class="cell" v-for="(item,index) in suppliers" :key="index">{{item}}</div>
                </div>
              </div>
            </a-col>
          </a-row>
        </div>
    </div>
</template>

<script>
import apis from '@/business/apis'
import moment from 'moment'
import { mapState } from 'vuex'
export default {
  inject: ['eventBus'],
  data () {
    return {
      canteens: [{}],
      cooks: ['', ''],
      suppliers: ['', '']
    }
  },
  computed: {
    ...mapState('nutrition', [
      'currentClass'
    ])
  },
  methods: {
    async loadData (selectedDate) {
      if (!this.$route.params || !this.$route.params.userId) return
      if (!selectedDate) {
        this.canteens = [{}]
        this.cooks = ['', '']
        this.suppliers = ['', '']
        return
      }
      const query = '?userId=' + this.$route.params.userId + '&date=' + selectedDate.unix()
      const response = await this.$http.get(apis.nutrition.relatedCanteen + query)
      if (!response || response.body.err) return
      const data = response.body
      this.canteens = data.canteens && data.canteens.length ? data.canteens : [{}]
      this.cooks = data.cooks && data.cooks.length ? data.cooks : ['', '']
      this.suppliers = data.suppliers && data.suppliers.length ? data.suppliers : ['', '']
    }
  },
  mounted () {
    this.loadData(this.currentClass.date ? moment(this.currentClass.date) : moment())
    this.eventBus.$on('nutrition-date-change', this.loadData)
  },
  beforeDestroy () {
    this.eventBus.$off('nutrition-date-change', this.loadData)
  }
}
</script>
