<template>
  <div class="student-nutrition-info">
    <a-breadcrumb class="bread">
      <a-breadcrumb-item>
        <a-icon type="home" />
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        <router-link :to="{name: 'NutritionMonitorIndex'}">警员营养监测</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        <router-link :to="{name: 'ClassNutritionDetail'}">{{currentClass.name}}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item class="current">营养报告</a-breadcrumb-item>
    </a-breadcrumb>
    <personal-info></personal-info>
    <div class="record">
      <div class="title">
        <h1>饮食记录</h1>
        <div class="picker">
          <a-date-picker size="large" :allowClear='false' v-model="selectedDate" @change="onDateChange" :disabled-date="disabledDate" />
        </div>
      </div>
      <suggestion></suggestion>
      <meal-detail></meal-detail>
      <related-personnel></related-personnel>
      <related-canteen></related-canteen>
    </div>
  </div>
</template>

<script>
import personalInfo from '@/components/nutritionMonitor/reportDetail/PersonalInfo'
import suggestion from '@/components/nutritionMonitor/reportDetail/Suggestion'
import mealDetail from '@/components/nutritionMonitor/reportDetail/MealDetail'
import relatedPersonnel from '@/components/nutritionMonitor/reportDetail/RelatedPersonnel'
import relatedCanteen from '@/components/nutritionMonitor/reportDetail/RelatedCanteen'
import { mapState } from 'vuex'
import moment from 'moment'
export default {
  inject: ['eventBus'],
  components: {
    personalInfo,
    suggestion,
    mealDetail,
    relatedPersonnel,
    relatedCanteen
  },
  data () {
    return {
      selectedDate: null
    }
  },
  computed: {
    ...mapState('nutrition', [
      'currentClass'
    ])
  },
  methods: {
    onDateChange (data) {
      this.eventBus.$emit('nutrition-date-change', data)
    },
    disabledDate (date) {
      const now = moment()
      if (date <= now && date > now.subtract(15, 'days')) return false
      return true
    }
  },
  created () {
    this.selectedDate = this.currentClass.date ? moment(this.currentClass.date) : moment()
  }
}
</script>
<style lang="less">
.student-nutrition-info {
  .bread {
    margin-top: -4px;
    margin-bottom: 16px;
    .current {
      font-size: 20px;
    }
  }
  .personal-info {
    border: 1px solid #e7e7eb;
    background: #fff;
    border-radius: 6px;
    padding: 16px;
    .avin {
      display: flex;
      border-bottom: 1px dotted #e7e7eb;
      padding-bottom: 16px;
      margin-bottom: 16px;
      .avatar {
        margin-top: 4px;
        margin-right: 16px;
      }
      .info {
        flex: 1;
        .num {
          font-size: 18px;
          font-weight: bold;
        }
        .cein {
          .row {
            margin-top: 6px;
            .ant-divider {
              margin: 0 16px;
            }
            .cell {
              display: inline-block;
            }
          }
        }
      }
    }
    .basic {
      display: flex;
      align-items: center;
      .info {
        flex: 1;
        display: flex;
        align-items: center;
        .item {
          flex: 1;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            top: 4px;
            right: 0;
            bottom: 4px;
            background: #e7e7eb;
            width: 1px;
          }
          .icon {
            width: 40px;
            height: 40px;
            margin: 0 auto;
            img {
              width: 100%;
            }
          }
          .text {
            text-align: center;
            margin-top: 8px;
          }
        }
      }
      .bmi {
        width: 360px;
        padding-left: 28px;
        .bminum {
          display: flex;
          justify-content: space-between;
          margin-bottom: 12px;
          .item {
            display: flex;
            align-items: center;
            .value {
              b {
                margin-right: 8px;
              }
            }
          }
        }
        .chart {
          position: relative;
          .icon {
            position: absolute;
            z-index: 1;
            top: -10px;
            margin-left: -12px;
            width: 24px;
            height: 24px;
            img {
              width: 100%;
            }
          }
          .progress {
            display: flex;
            align-items: center;
            .item {
              flex: 1;
              margin-right: 1px;
              .pro {
                width: 100%;
                height: 8px;
              }
              .text {
                margin-top: 12px;
                font-size: 12px;
                color: #9a9a9a;
                line-height: 1.2;
              }
              &:nth-child(1) {
                .pro {
                  background: #9fc0f8;
                  border-top-left-radius: 8px;
                  border-bottom-left-radius: 8px;
                }
              }
              &:nth-child(2) {
                .pro {
                  background: #86d680;
                }
              }
              &:nth-child(3) {
                .pro {
                  background: #f1cc5d;
                }
              }
              &:nth-child(4) {
                .pro {
                  background: #f3625c;
                  border-top-right-radius: 8px;
                  border-bottom-right-radius: 8px;
                }
              }
            }
          }
        }
      }
    }
  }
  .record {
    border: 1px solid #e7e7eb;
    background: #fff;
    border-radius: 6px;
    padding: 16px;
    margin-top: 24px;
    > .title {
      display: flex;
      align-items: center;
      h1 {
        margin: 0;
        font-size: 20px;
      }
      .picker {
        margin-left: 16px;
      }
    }
    > .chart {
      margin: 16px -8px 0;
      .item {
        padding: 12px 12px 8px;
        margin: 0 8px;
        position: relative;
        border: 1px solid #e7e7eb;
        border-radius: 6px;
        display: flex;
        align-items: center;
        .tenu {
          flex: 1;
          .num {
            b {
              font-size: 24px;
            }
            .x {
              margin: 0 2px;
            }
          }
        }
        .progress {
          flex: 1;
        }
      }
    }
    > .dinner {
      margin: 8px -8px 0;
      .item {
        margin: 8px;
        position: relative;
        border: 1px solid #e7e7eb;
        border-radius: 6px;
        overflow: hidden;
        &::after {
          content: "";
          position: absolute;
          top: 8px;
          right: 0;
          bottom: 8px;
          background: #e7e7eb;
          width: 1px;
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
        .tinu {
          display: flex;
          align-items: center;
          background: #f6f8f9;
          padding: 8px 12px;
          h1 {
            font-size: 16px;
            font-weight: bold;
            flex: 1;
            margin: 0;
          }
          .num {
            b {
              font-size: 16px;
            }
            .x {
              margin: 0 2px;
            }
          }
        }
        .list {
          padding: 0 12px;
          height: 137px;
          overflow-y: scroll;
          .ant-empty {
            margin: 0;
            padding-top: 34px;
          }
          .cell {
            border-bottom: 1px dotted #e7e7eb;
            display: flex;
            padding: 12px 0;
            .fonu {
              flex: 1;
              display: flex;
              .food {
                max-width: 200px;
              }
              .nu {
                margin-left: 8px;
              }
            }
          }
        }
      }
    }
    .correlation-people {
      margin-top: 12px;
      .title {
        display: flex;
        align-items: center;
        .text {
          font-size: 20px;
          flex: 1;
        }
      }
      .list {
        margin: 8px -8px 0;
        .item {
          margin: 8px;
          border: 1px solid #e7e7eb;
          border-radius: 6px;
          display: flex;
          padding: 10px 12px 8px;
          .avatar {
            margin-top: 4px;
            margin-right: 12px;
          }
          .info {
            flex: 1;
            .name {
              font-size: 16px;
              font-weight: bold;
              max-width: 170px;
            }
          }
          .other {
            .cell {
              font-size: 13px;
              .num {
                margin: 0 2px;
              }
            }
          }
        }
      }
    }
    .correlation-canteen {
      margin-top: 16px;
      .title {
        font-size: 20px;
      }
      .list {
        margin: 8px -8px 0;
        .item {
          margin: 8px;
          border: 1px solid #e7e7eb;
          border-radius: 6px;
          overflow: hidden;
          .hd {
            font-size: 16px;
            font-weight: bold;
            background: #f6f8f9;
            padding: 8px 12px;
          }
          .bd {
            padding:0 12px;
            .cell {
              padding: 12px 0;
              border-bottom: 1px dotted #e7e7eb;
              &:last-child{
                border:none;
              }
            }
          }
        }
      }
    }
  }
}
</style>
