<template>
<div class="dinner">
        <a-row>
          <a-col :span="12">
            <div class="item">
              <div class="tinu">
                <h1>早餐</h1>
                <div class="num" :class="{fred:isExcess(breakfastSuggest)}">
                  <b>{{Math.round(breakfastSuggest.heatkc)}}</b>
                  <span class="x">/</span>{{breakfastSuggest.suggestHeatkc}}千卡
                </div>
              </div>
              <div class="list">
                <a-empty
                  :class="{hideEmptyDivMeal: breakfast&&breakfast.length}"
                  :image="simpleImage"
                  description="暂无记录"
                />
                <template v-for="(item,index) in breakfast">
                  <div class="cell" :key="index">
                    <div class="fonu">
                      <div class="food ellipsis-l1">{{item.name}}</div>
                      <div class="nu">{{item.total_gram ? item.total_gram + 'g' : '(未知重量)'}}</div>
                    </div>
                    <div class="num">{{foodHeatkc(item)}}</div>
                  </div>
                </template>
              </div>
            </div>
          </a-col>
          <a-col :span="12">
            <div class="item">
              <div class="tinu">
                <h1>午餐</h1>
                <div class="num" :class="{fred: isExcess(lunchSuggest)}">
                  <b>{{Math.round(lunchSuggest.heatkc)}}</b>
                  <span class="x">/</span>{{lunchSuggest.suggestHeatkc}}千卡
                </div>
              </div>
              <div class="list">
                <a-empty
                  :class="{hideEmptyDivMeal: lunch&&lunch.length}"
                  :image="simpleImage"
                  description="暂无记录"
                />
                <template v-for="(item,index) in lunch">
                  <div class="cell" :key="index">
                    <div class="fonu">
                      <div class="food ellipsis-l1">{{item.name}}</div>
                      <div class="nu">{{item.total_gram ? item.total_gram + 'g' : '(未知重量)'}}</div>
                    </div>
                    <div class="num">{{foodHeatkc(item)}}</div>
                  </div>
                </template>
              </div>
            </div>
          </a-col>
          <a-col :span="12">
            <div class="item">
              <div class="tinu">
                <h1>晚餐</h1>
                <div class="num" :class="{fred: isExcess(dinnerSuggest)}">
                  <b>{{Math.round(dinnerSuggest.heatkc)}}</b>
                  <span class="x">/</span>{{dinnerSuggest.suggestHeatkc}}千卡
                </div>
              </div>
              <div class="list">
                <a-empty
                  :class="{hideEmptyDivMeal: dinner&&dinner.length}"
                  :image="simpleImage"
                  description="暂无记录"
                />
                <template v-for="(item,index) in dinner">
                  <div class="cell" :key="index">
                    <div class="fonu">
                      <div class="food ellipsis-l1">{{item.name}}</div>
                      <div class="nu">{{item.total_gram ? item.total_gram + 'g' : '(未知重量)'}}</div>
                    </div>
                    <div class="num">{{foodHeatkc(item)}}</div>
                  </div>
                </template>
              </div>
            </div>
          </a-col>
          <a-col :span="12">
            <div class="item">
              <div class="tinu">
                <h1>宵夜</h1>
                <div class="num"><b>{{Math.round(nightSnackSuggest.heatkc)}}</b><span class="x">/</span>{{nightSnackSuggest.suggestHeatkc}}千卡</div>
              </div>
              <div class="list">
                <a-empty
                  :class="{hideEmptyDivMeal: nightSnack&&nightSnack.length}"
                  :image="simpleImage"
                  description="暂无记录"
                />
                <template v-for="(item,index) in nightSnack">
                  <div class="cell" :key="index">
                    <div class="fonu">
                      <div class="food ellipsis-l1">{{item.name}}</div>
                      <div class="nu">{{item.total_gram ? item.total_gram + 'g' : '(未知重量)'}}</div>
                    </div>
                    <div class="num">{{foodHeatkc(item)}}</div>
                  </div>
                </template>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
</template>

<script>
import { mapState } from 'vuex'
import apis from '@/business/apis'
import moment from 'moment'
import { Empty } from 'ant-design-vue'
export default {
  inject: ['eventBus'],
  data () {
    return {
      breakfast: [],
      lunch: [],
      dinner: [],
      nightSnack: [],
      suggest_heatkc: 0
    }
  },
  computed: {
    ...mapState('nutrition', [
      'currentClass'
    ]),
    breakfastSuggest () {
      return this.calcHeatkc(1)
    },
    lunchSuggest () {
      return this.calcHeatkc(2)
    },
    dinnerSuggest () {
      return this.calcHeatkc(3)
    },
    nightSnackSuggest () {
      return this.calcHeatkc(4)
    }
  },
  methods: {
    async loadData (selectedDate) {
      if (!this.$route.params || !this.$route.params.userId) return
      if (!selectedDate) {
        this.breakfast = []
        this.lunch = []
        this.dinner = []
        this.nightSnack = []
        this.syncNutritionData()
        return
      }
      const query = '?userId=' + this.$route.params.userId + '&date=' + selectedDate.unix()
      const response = await this.$http.get(apis.nutrition.mealData + query)
      if (!response || response.body.err) return
      this.breakfast = response.body.breakfast
      this.lunch = response.body.lunch
      this.dinner = response.body.dinner
      this.nightSnack = response.body.nightSnack
      this.suggest_heatkc = response.body.suggest_heatkc
      this.syncNutritionData()
    },
    calcHeatkc (type) {
      let temp = this.nightSnack
      let suggestHeatkc = 0
      switch (type) {
        case 1:
          temp = this.breakfast
          suggestHeatkc = Math.round(this.suggest_heatkc * 0.3)
          break
        case 2:
          temp = this.lunch
          suggestHeatkc = Math.round(this.suggest_heatkc * 0.4)
          break
        case 3:
          temp = this.dinner
          suggestHeatkc = Math.round(this.suggest_heatkc * 0.3)
          break
      }
      let heatkc = 0
      let fat = 0
      let carbohydr = 0
      let protein = 0
      temp.forEach(item => {
        heatkc += item.heatkc
        fat += item.fat
        carbohydr += item.carbohydr
        protein += item.protein
      })
      return { heatkc: heatkc, suggestHeatkc: suggestHeatkc, fat: fat, carbohydr: carbohydr, protein: protein }
    },
    syncNutritionData () {
      const heatkc = this.breakfastSuggest.heatkc + this.lunchSuggest.heatkc + this.dinnerSuggest.heatkc + this.nightSnackSuggest.heatkc
      const fat = this.breakfastSuggest.fat + this.lunchSuggest.fat + this.dinnerSuggest.fat + this.nightSnackSuggest.fat
      const carbohydr = this.breakfastSuggest.carbohydr + this.lunchSuggest.carbohydr + this.dinnerSuggest.carbohydr + this.nightSnackSuggest.carbohydr
      const protein = this.breakfastSuggest.protein + this.lunchSuggest.protein + this.dinnerSuggest.protein + this.nightSnackSuggest.protein
      this.eventBus.$emit('nutrition-update-mealdata', this.suggest_heatkc, Math.round(heatkc), Math.round(protein), Math.round(fat), Math.round(carbohydr))
    },
    foodHeatkc (item) {
      return item && item.heatkc > 0 ? Math.round(item.heatkc) + '千卡' : '（未查询到营养数据）'
    },
    isExcess (item) {
      if (!item) return false
      return Math.round(item.heatkc) > item.suggestHeatkc
    }
  },
  mounted () {
    this.eventBus.$on('nutrition-date-change', this.loadData)
    this.loadData(this.currentClass.date ? moment(this.currentClass.date) : moment())
  },
  beforeCreate () {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE
  },
  beforeDestroy () {
    this.eventBus.$off('nutrition-date-change', this.loadData)
  }
}
</script>
<style scoped>
.hideEmptyDivMeal {
  display:none;
}
</style>
