<template>
    <div class="correlation-people">
        <div class="title">
          <div class="text">关联人员名单（<b>{{data.length}}</b>人）</div>
          <div class="button">
            <a-button
              type="primary"
              size="large"
              @click="exportExcel"
              :loading="isDownloading"
              :disabled="!data||!data.length"
            >导出数据</a-button>
          </div>
        </div>
        <div class="list" style="overflow-y: auto;max-height: 400px;">
          <a-empty
            :class="{hideEmptyDiv:data&&data.length}"
            :image="simpleImage"
            description="暂无记录"
          />
          <a-row>
            <template v-for="(item, index) in data">
               <a-col :span="6" :key="index">
                <div class="item">
                  <div class="avatar">
                    <a-avatar
                      :src="avatarUrl"
                      :size="40"
                    />
                  </div>
                  <div class="info">
                    <div class="name ellipsis-l1">{{item.name}}</div>
                    <div class="other">
                      <div class="cell">{{item.group}}</div>
                      <div class="cell">关联食物<b class="num fred">{{item.food_count}}</b>个</div>
                    </div>
                  </div>
                </div>
              </a-col>
            </template>
          </a-row>
        </div>
    </div>
</template>

<script>
import apis from '@/business/apis'
import moment from 'moment'
import { Empty } from 'ant-design-vue'
import { mapState } from 'vuex'
import downloadjs from 'downloadjs'
import avatarUrl from '@/assets/images/default-avatar.jpg'
export default {
  inject: ['eventBus'],
  data () {
    return {
      avatarUrl,
      isDownloading: false,
      data: [],
      date: null
    }
  },
  computed: {
    ...mapState('nutrition', [
      'currentClass'
    ])
  },
  methods: {
    async loadData (selectedDate) {
      if (!this.$route.params || !this.$route.params.userId) return
      if (!selectedDate) {
        this.data = []
        return
      }
      this.date = selectedDate
      const query = '?userId=' + this.$route.params.userId + '&date=' + selectedDate.unix()
      const response = await this.$http.get(apis.nutrition.relatedPersonnel + query)
      if (!response || response.body.err) return
      this.data = response.body.list
    },
    async exportExcel () {
      this.$message.info('正在导出...')
      this.isDownloading = true
      setTimeout(() => {
        this.isDownloading = false
      }, 1000)
      const data = { userId: this.$route.params.userId, date: this.date.unix() }
      const response = await this.$http.post(apis.nutrition.exportRelatedCanteen, data, { responseType: 'blob' })
      if (!response || !response.ok) {
        this.$message.info('导出失败')
        return
      }
      downloadjs(
        await response.blob(),
        `关联名单_${this.date.format('YYYY-MM-DD')}.xlsx`,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      )
    }
  },
  mounted () {
    this.date = this.currentClass.date ? moment(this.currentClass.date) : moment()
    this.loadData(this.date)
    this.eventBus.$on('nutrition-date-change', this.loadData)
  },
  beforeCreate () {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE
  },
  beforeDestroy () {
    this.eventBus.$off('nutrition-date-change', this.loadData)
  }
}
</script>
<style scoped>
.hideEmptyDiv {
  display:none;
}
</style>
