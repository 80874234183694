<template>
  <div class="personal-info">
      <div class="avin">
        <div class="avatar">
          <a-avatar
            :src="avatarUrl"
            :size="64"
          />
        </div>
        <div class="info">
          <div class="num">编号：{{data.num}}</div>
          <div class="cein">
            <div class="row">
              <span class="cell">
                <span class="title">姓名：</span>
                <span class="value">{{data.name}}</span>
              </span>
              <a-divider type="vertical" />
              <span class="cell">
                <span class="title">所属分局：</span>
                <span class="value">{{currentSchool.name}}</span>
              </span>
            </div>
            <div class="row">
              <span class="cell">
                <span class="title">部门：</span>
                <span class="value">{{data.group}}</span>
              </span>
              <a-divider type="vertical" />
              <span class="cell">
                <span class="title">联系方式：</span>
                <span class="value">{{data.contact_mobile}}</span>
              </span>
            </div>
            <div class="row">
              <span class="cell">
                <span class="title">状态：</span>
                <span class="value fgreen" :class="{fred:data.state=='unbind',fgreen:data.state=='bound'}">{{userState}}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="basic">
        <div class="info">
          <div class="item">
            <div class="icon"><img src="~@/assets/images/icon-cake.png" /></div>
            <div class="text">年龄：{{userAge}}岁</div>
          </div>
          <div class="item">
            <div class="icon"><img src="~@/assets/images/icon-height.png" /></div>
            <div class="text">身高：{{data.height}}cm</div>
          </div>
          <div class="item">
            <div class="icon"><img src="~@/assets/images/icon-weight.png" /></div>
            <div class="text">体重：{{data.weight}}kg</div>
          </div>
          <div class="item">
            <div class="icon"><img src="~@/assets/images/icon-sports.png" /></div>
            <div class="text">运动频率：{{sportFreq}}</div>
          </div>
        </div>
        <div class="bmi">
          <div class="bminum">
            <div class="item">
              <div class="title"></div>
              <div class="value"><b></b></div>
            </div>
            <div class="item">
              <div class="title">BMI值：</div>
              <div class="value"><b>{{BMI.value}}</b>{{BMI.des}}</div>
            </div>
          </div>
          <div class="chart">
            <div
              ref="bmiChartDiv"
              class="icon"
              style="left:60%;"
            ><img src="~@/assets/images/emoji-good.png" /></div>
            <div class="progress">
              <div class="item">
                <div class="pro"></div>
                <div class="text">偏瘦<br>&lt;18.5</div>
              </div>
              <div class="item">
                <div class="pro"></div>
                <div class="text">正常<br>&lt;18.5~24</div>
              </div>
              <div class="item">
                <div class="pro"></div>
                <div class="text">超重<br>&lt;24~28</div>
              </div>
              <div class="item">
                <div class="pro"></div>
                <div class="text">肥胖<br>&gt;28</div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import apis from '@/business/apis'
import { bindStates } from '@/business/enums'
import avatarUrl from '@/assets/images/default-avatar.jpg'
export default {
  data () {
    return {
      avatarUrl,
      data: {}
    }
  },
  computed: {
    ...mapState('school', [
      'currentSchool'
    ]),
    userAge () {
      if (!this.data || !this.data.birthday) return 0
      const now = moment()
      return now.diff(moment(this.data.birthday), 'years')
    },
    userState () {
      return bindStates[this.data.state]
    },
    BMI () {
      if (!this.data.height || !this.data.weight) {
        return { value: 0, des: '' }
      }
      const value = this.data.weight / (this.data.height * this.data.height * 0.0001)
      if (value < 18.5) {
        return { value: value.toFixed(2), des: '偏瘦' }
      }
      if (value >= 18.5 && value < 24) {
        return { value: value.toFixed(2), des: '正常' }
      }
      if (value >= 24 && value < 28) {
        return { value: value.toFixed(2), des: '超重' }
      }
      return { value: value.toFixed(2), des: '肥胖' }
    },
    sportFreq () {
      switch (this.data.sport_freq) {
        case 1:
          return '基本不运动'
        case 2:
          return '少量运动'
        case 3:
          return '中等量运动'
        case 4:
          return '大量运动'
        case 5:
          return '超大量运动'
      }
      return ''
    }
  },
  methods: {
    async loadData () {
      if (!this.$route.params || !this.$route.params.userId) return
      const response = await this.$http.get(apis.user.detail + this.$route.params.userId)
      this.data = response.body.user || {}
      this.setBmiDivStyle()
    },
    setBmiDivStyle () {
      if (!this.$refs.bmiChartDiv) return
      const value = this.BMI.value
      let left = 0
      if (value < 18.5) {
        left = 0.25 * value / 18.5
      } else if (value >= 18.5 && value < 24) {
        left = 0.25 + 0.25 * (value - 18.5) / 5.5
      } else if (value >= 24 && value < 28) {
        left = 0.5 + 0.25 * (value - 24) / 4
      } else {
        left = 0.75 + 0.25 * (value - 28) / 10
        left = left > 1 ? 1 : left
      }
      this.$refs.bmiChartDiv.style.left = (left * 100).toFixed(1) + '%'
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
